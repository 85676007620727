.message-list {
    background    : white;
    min-width     : 250px;
    border-radius : 6px;
    overflow-y    : scroll;
    /*box-sizing: border-box;*/
    border        : 1px solid rgba(53, 53, 53, .3);
    padding       : 4px;
    box-shadow    : 2px 3px 3px rgba(64, 64, 64, .5);
    margin-top    : 8px;
    z-index       : 1500;
}

.messageListItem {
    min-width             : 0;
    max-width             : 256px;
    padding               : 4px 4px !important;
    white-space           : nowrap !important;
    overflow              : hidden !important;
    text-overflow         : ellipsis !important;
    -webkit-touch-callout : none !important;
    -webkit-user-select   : none !important;
    -khtml-user-select    : none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    display:inline-block !important;
    cursor : pointer !important;

}

.messageListItem > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.titles {
 font-weight:700;

}

.date {
 font-size: .8em;
}
