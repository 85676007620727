.selectedTab {
    background              : #d8edbb !important;
    order                   : 1;
    display                 : inline-block;
    padding                 : 1rem 2rem;
    margin-right            : 0.2rem;
    cursor                  : pointer;
    font-weight             : bold;
    transition              : background ease 0.2s;
    border-top              : 1px solid #d8edbb;
    border-left             : 1px solid #d8edbb;
    border-right            : 1px solid #d8edbb;
    border-bottom           : none;
    border-top-left-radius  : 6px;
    border-top-right-radius : 6px;
    margin-bottom           : -1px;
}

.unSelectedTab {
    order                   : 1;
    display                 : inline-block;
    padding                 : 1rem 2rem;
    margin-right            : 0.2rem;
    cursor                  : pointer;
    background              : #f3eb77;
    font-weight             : bold;
    transition              : background ease 0.2s;
    border-bottom           : none;
    border-top-left-radius  : 6px;
    border-top-right-radius : 6px;
    margin-bottom           : -1px;
    max-width               : 48%;
}

.selectedPanel {
    background    : #d8edbb !important;
    display       : block;
    border-top    : 1px solid #d8edbb;
    border-right  : 1px solid #d8edbb;
    padding       : 1rem;
    border-radius : 0 6px 6px 6px;
}

.notes {
    display    : block;
    max-height : 45vh;
    overflow-y : scroll;
}

.noteEntry {
}

.notesTabPanel {
}

.box {
    background    : #fff !important;
    padding       : 10px;
    border-radius : 4px !important;
    margin        : 5px 0;
}
.pending-box {
    background    : rgba(255,26,53,.70) !important;
    padding       : 10px;
    border-radius : 4px !important;
    margin        : 5px 0;
}
.history-box {
    background    : var(--smartpcmh-line-color) !important;
    padding       : 10px;
    border-radius : 4px !important;
    margin        : 5px 0;
}

.box_wrap {
    box-sizing : border-box;
    padding    : 20px 0;
    border-top : 1px solid #ccc; }

.box_footer {
    overflow      : hidden;
    background    : #f5f5f8;
    padding       : 10px 20px;
    border-radius : 0 0 4px 4px; }

.box h2 {
    margin        : 0 0 20px;
    padding       : 0 0 10px;
    border-bottom : 1px solid #ccc;
    color         : #666;
    font-weight   : normal; }

.box textarea {
    -webkit-appearance : none;
    -moz-appearance    : none;
    appearance         : none;
    outline            : none;
    color              : #666;
    background         : #fff;
    border             : 1px solid #dee3eb;
    border-radius      : 4px;
    resize             : none;
    text-align         : left;
    width              : 100%;
    height             : 120px;
    box-sizing         : border-box;
    padding            : 8px;
    overflow-y         : scroll; }

.box .counter {
    padding   : 0 16px;
    font-size : 13px;
    color     : #666; }

.box #count.error {
    color : #e73275; }

.box button {
    background-color : #89bf40;
    color            : #ffffff;
    border-radius    : 4px;
    cursor           : pointer;
    border           : none;
    outline          : none;
    padding          : 10px 24px;
    transition       : 0.5s;
    /*font-family      : "Lato", sans-serif;*/
    font-size        : .80em;
}

.box button:hover {
    background-color : #f3eb77;
    color            : #292929; }

.box button:active {
    box-shadow : none;
    filter     : brightness(0.8); }

.box button:disabled {
    cursor           : default;
    box-shadow       : none;
    background-color : #aaa;
    color            : #999; }

.agendaTabList {
    padding                     : 0;
    display                     : inline-flex;
    margin-bottom               : 0 !important;
    -webkit-margin-start        : 0px;
    margin-inline-start         : 0px;
    -webkit-margin-end          : 0px;
    margin-inline-end           : 0px;
    -webkit-tap-highlight-color : transparent;
}

.commenter {
    color     : #73a92e;
    /*font-family : "Lato", sans-serif;*/
    font-size : 1.2em;
}

.dateEntered-label {
    font-size   : 0.8vw;
    font-weight : bold;
    color       : var(--smartpcmh-alt-black);
    padding     : 0;

}

.dateEntered {
    /*font-family : "Lato", sans-serif;*/
    font-size : 0.8vw;
    color     : #535353;
}

.noteText {
    /*font-family : "Lato", sans-serif;*/
    font-size : 1.1vw;
    color     : #080808;
}

.note-edit {
    cursor : pointer;
}

.undo {
    color : var(--smartpcmh-alt-black)
}

.save {
    color : var(--smartpcmh-green);
}

.history {
    color           : var(--smartpcmh-grey-dark);
    font-size       : 12px;
    text-decoration : line-through;
}

.history-date {
    color     : var(--smartpcmh-grey-dark);
    font-size : 8px;
}

.history-author {
    color         : var(--smartpcmh-grey-dark);
    font-size     : 10px;
    overflow      : hidden;
    text-overflow : ellipsis;
    white-space   : nowrap;
}

.history-block {
    border-bottom : 1px solid var(--smartpcmh-grey-dark);
    padding       : 5px 0;
}

