.assessment-batches {
    display        : flex;
    flex-direction : column;
    width          : 100%;
    height         : 92vh;
}

.assessment-batches .grid {
    flex : 1;
}

.assessment-batches .sub-grid {
    padding : 10px;
}

.assessment-batches .grid .expander {
    block-size    : 100%;
    align-content : center;
    text-align    : center;
    cursor        : pointer;
}

.wait {
    block-size    : 100%;
    align-content : center;
    text-align    : center;
}

.icon {
    block-size    : 100%;
    align-content : center;
    text-align    : center;
    font-size : 1.5em;
    color     : #a9a9a9;
    cursor    : pointer;
    margin    : auto;
}


.mlln6zg7-0-0-beta-47 {
    @layer rdg.MeasuringCell {
        contain: strict;
        grid-row: 1;
        visibility: hidden;
    }
}

