.teamItem {
    margin        : 16px 0;
    padding       : 16px;
    background    : #f5f5f8;
    border-radius : 6px;
}

.teamItem .name {
    /*font-family : "Lato", sans-serif;*/
    font-size   : 1.5em;
    color       : #535353;
}

.teamItem .edit {
    font-size : 2.5em;
    color     : #535353;
    cursor    : pointer;
}

.teamItem .editing {
    width : 100%;
}

