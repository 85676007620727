@import "../../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../../../../../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../../../../../node_modules/@syncfusion/ej2-react-inputs/styles/material.css";


.e-control-wrapper.e-slider-container .e-slider .e-handle {
    background-color : var(--smartpcmh-green);
}


/*.e-read-only div[class='.e-slider-button .e-first-button']  {*/
/*    display: none;*/
/*    background: var(--smartpcmh-alt-black);*/
/*    */
/*}*/
.e-control-wrapper.e-slider-container .e-slider-button,
.e-control-wrapper.e-slider-container .e-slider .e-range,
.e-slider-tooltip.e-tooltip-wrap.e-popup.e-tooltip-wrap.e-popup.e-material-default {
    background : var(--smartpcmh-green);
}

.e-read-only.e-control-wrapper.e-slider-container .e-slider-button {
    display : none;
}

.e-read-only.e-control-wrapper.e-slider-container .e-slider .e-range,
.e-read-only.e-slider-tooltip.e-tooltip-wrap.e-popup.e-tooltip-wrap.e-popup.e-material-default,
.e-read-only div.e-handle.e-handle-first.e-control.e-tooltip.e-lib {
    background : var(--smartpcmh-alt-black);
}

/*.e-control-wrapper.e-slider-container .e-slider .e-range {*/
/*    background: var(--smartpcmh-alt-black);*/
/*}*/
