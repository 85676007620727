.agendaItemRow {
    background    : #ffffff;
    padding       : 6px;
    margin-top    : 8px;
    border        : 1px solid #dee3eb;
    border-radius : 6px;
    align-items   : center;
    justify-items : start;

}

.completedBox {
    display       : block;
    width         : 2em;
    height        : 2em;
    padding       : 3px;
    border-radius : 2em;
    border        : 1px solid #dee3eb;
    text-align    : center;
    cursor        : pointer;
}

.completedBox svg {
    fill           : var(--smartpcmh-green-light);
    width          : 16px;
    height         : 16px;
    display        : inline-block;
    vertical-align : middle;

}

.agendaItemOrder {
    font-weight : 700;
    color       : var(--smartpcmh-green);
}

.agendaItemName {
    color       : #000000;
    /*font-family : "Lato", sans-serif;*/
}