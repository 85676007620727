.sideCol {
    position      : relative;
    width         : 100%;
    height        : 81vh;
    padding       : 8px;
    padding-top   : 0;
    background    : #f5f5f8;
    border-radius : 6px;
}

.sideCol h3.sectionTitle {
    margin                  : 0 -8px;
    padding                 : 8px 16px;
    display                 : block;
    background              : #d8edbb;
    border-top-left-radius  : 6px;
    border-top-right-radius : 6px;
    font-size               : 1.17em;
    font-weight             : 400;
}

.tm_profilesCol {
    position   : absolute;
    bottom     : 0;
    top        : 52px;
    width      : auto;
    overflow   : hidden;
    overflow-y : scroll; }

.tm_profilesCol .tm_cardsGrid {
    display         : flex;
    flex-wrap       : wrap;
    justify-content : space-between; }

.tm_profilesCol .tm_cardsGrid .tm_cardAction {
    display : block; }

.tm_profilesCol .tm_cardsGrid .tm_profileCard {
    background    : white;
    flex-basis    : 48.3%;
    padding       : 8px;
    margin-bottom : 15px;
    border-radius : 6px;
    transition    : 0.5s; }

.tm_profilesCol .tm_cardsGrid .tm_profileCard:hover {
    background : #d8edbb;
    cursor     : pointer; }

.tm_profilesCol .tm_cardsGrid .tm_profileCard .tm_profileCardInner {
    background    : #ffffff;
    padding       : 32px 16px;
    display       : grid;
    justify-items : center; }

.tm_profilesCol .tm_cardsGrid .tm_profileCard .tm_profileCardInner .th_profileCardInfo h3,
.tm_profilesCol .tm_cardsGrid .tm_profileCard .tm_profileCardInner .th_profileCardInfo p,
.tm_profilesCol .tm_cardsGrid .tm_profileCard .tm_profileCardInner .th_profileCardInfo span {
    display    : block;
    margin     : 0;
    padding    : 0;
    text-align : center; }

.tm_profilesCol .tm_cardsGrid .tm_profileCard .tm_profileCardInner .th_profileCardInfo h3 {
    color : #73a92e; }

.tm_profilesCol .tm_cardsGrid .tm_profileCard .tm_profileCardInner .th_profileCardInfo a {
    color : #36373f; }

.tm_profilesCol .tm_cardsGrid .tm_profileCard .tm_profileCardInner .th_profileCardInfo span {
    color         : #4f504b;
    font-size     : 13px;
    line-height   : 24px;
    border        : 1px solid #f5f5f8;
    border-radius : 20px;
    margin-top    : 16px; }

.tm_profilesCol .tm_cardsGrid .tm_profileCard .tm_profileCardInner .tm_profileCardPic {
    display       : block;
    overflow      : hidden;
    width         : 55px;
    height        : 55px;
    border-radius : 30px;
    margin-bottom : 8px; }

.tm_profilesCol .tm_cardsGrid .tm_profileCard .tm_profileCardInner .tm_profileCardPic img {
    display : block;
    height  : 55px; }
