.header-container {
    background            : #ffffff;
    display               : grid;
    grid-template-columns : repeat(3, auto);
    align-items           : center;
    justify-items         : start;
    border-bottom         : 1px solid #ededf0;
    margin                : 0 -16px;
    padding               : 4px 16px;
}

.header-container h2 {
    /*font-family           : "Lato", sans-serif;*/
    line-height           : 1.3;
    display               : block;
    font-size             : 1.8vw;
    -webkit-margin-before : 0.83em;
    -webkit-margin-after  : 0.83em;
    -webkit-margin-start  : 0;
    -webkit-margin-end    : 0;
    font-weight           : bold;
    margin                : 0;
}

.header-container .tabs-link {
    --ts                  : 3;
    margin                : 0 auto;
    display               : grid;
    grid-template-columns : repeat(var(--ts), auto);
    align-items           : center;
}
