.agendaItem {
    margin        : 16px 0;
    padding       : 16px;
    background    : #f5f5f8;
    border-radius : 6px;
    cursor        : hand;
}

.agendaItem .name {
    /*font-family : "Lato", sans-serif;*/
    font-size   : 1.5em;
    color       : #535353;
}