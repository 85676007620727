.pcmh-huddle {

}

.pcmh-huddle .pcmh-content-header {
    margin-bottom : 8px;
    /*margin        : 0 -16px 6px -16px;*/
    background    : #ffffff;
    align-items   : center;
    justify-items : start;
    border-bottom : 1px solid var(--smartpcmh-line-color);
    border-radius : 0;
}

.pcmh-huddle .pcmh-content-header h2 {
    /*font-family           : "Lato", sans-serif;*/
    line-height           : 1.20;
    display               : block;
    font-size             : 1.8vw;
    color                 : var(--smartpcmh-text-color);
    -webkit-margin-before : 0.83em;
    -webkit-margin-after  : 0.83em;
    -webkit-margin-start  : 0;
    -webkit-margin-end    : 0;
    font-weight           : bold;
}

.pcmh-huddle .pcmh-content-header .headerNav {
    /*border        : 1px solid #528651;*/
    border        : 1px solid var(--smartpcmh-button-border);
    border-radius : 6px;
    cursor        : pointer;
    padding       : 2% 3%;
    line-height   : 32px;
    margin        : 0 0.4rem;
    color         : var(--smartpcmh-green-dark);
}

.pcmh-huddle .pcmh-content-header .selected-date {
    /*font-family : "Lato", sans-serif;*/
    color     : var(--smartpcmh-text-color);
    font-size : 1.3vw;
}

.pcmh-huddle .selected-tab {
    background              : #fff !important;
    order                   : 1;
    display                 : inline-block;
    padding                 : 1rem 2rem;
    margin                  : 0 0.2rem -1px 0.2rem;
    /*margin-bottom           : -1px;*/
    /*margin-left: 8px;*/
    cursor                  : pointer;
    font-weight             : bold;
    transition              : background ease 0.2s;
    border-top              : 1px solid var(--smartpcmh-green-light);
    border-left             : 1px solid var(--smartpcmh-green-light);
    border-right            : 1px solid var(--smartpcmh-green-light);
    border-bottom           : none;
    border-top-left-radius  : 6px;
    border-top-right-radius : 6px;
}

.pcmh-huddle .un-selected-tab {
    background              : var(--smartpcmh-green-light);
    order                   : 1;
    display                 : inline-block;
    padding                 : 1rem 2rem;
    cursor                  : pointer;
    font-weight             : bold;
    transition              : background ease 0.2s;
    border-bottom           : none;
    border-top-left-radius  : 6px;
    border-top-right-radius : 6px;
    margin                  : 0 0.2rem -1px 0.2rem;
}

.pcmh-huddle .selected-panel {
    background    : #fff !important;
    display       : block;
    border-left   : 1px solid var(--smartpcmh-green-light);
    border-right  : 1px solid var(--smartpcmh-green-light);
    border-bottom : 1px solid var(--smartpcmh-green-light);
    border-top    : 1px solid var(--smartpcmh-green-light);
    padding       : .5rem;
    border-radius : 0 6px 6px 6px;
    /*min-height    : calc(100vh - 200px);*/
    margin        : 0 0.6rem -1px 0.6rem;
    max-height    : 85%;
}

.pcmh-huddle .tabs {
    height : 90%;
}

.pcmh-huddle .tabs > ul li:first-child {
    margin-left : .6rem;

}

.pcmh-huddle .tab-container {
    height     : calc(100vh - 250px);
    overflow-y : scroll;
}

.pcmh-huddle .headerButton {
    color         : var(--smartpcmh-text-color) !important;
    background    : #36373f;
    display       : block;
    line-height   : 32px;
    padding       : 0 16px;
    border        : 1px solid var(--smartpcmh-button-border);
    border-radius : 6px;
    cursor        : pointer;
}


.pcmh-huddle .newAgendaBox {
    width         : 100%;
    border        : 1px solid var(--smartpcmh-green);
    border-radius : 6px;
    box-shadow    : 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.pcmh-huddle .newAgendaBox .card-header {
    color      : #fff;
    background : var(--smartpcmh-green);
}


.pcmh-huddle .agendaTextField {
    color : #434551 !important;
}

.pcmh-huddle .agendaTextField fieldset {
    color        : #434551 !important;
    border-color : #434551 !important;
    /*border-color: #434551 !important;*/
}

.pcmh-huddle .agendaTextField input {
    color : #ffffff !important;
}

.pcmh-huddle .agendaFocused {
    color : #ffffff !important;
}


.pcmh-huddle .header-container .tabs-link {
    --ts                  : 3;
    margin                : 0 auto;
    display               : grid;
    grid-template-columns : repeat(var(--ts), auto);
    align-items           : center;
}
