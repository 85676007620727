.patient-search{
    border-bottom: 1px solid var(--smartpcmh-grey);
    padding: 0;
}

.patient-search .date-picker {
}

.patient-search .edit {
    font-size : 2.5em;
    color     : #535353;
    cursor    : pointer;
}

.patient-info{
    
}

