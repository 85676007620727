
.branding {
    display : block;
}

.branding img {
    display    : block;
    width      : 150px;
    background : #f5f5f8;
    margin     : 15px auto;
}

.navSmallFont {
    color         : #697277;
    font-size     : 0.813em;
    line-height   : 30px;
    border-bottom : 1px solid #ededf0;
}

.profile-wrapper {
    width    : 100%;
    position : relative;
    margin   : 0 auto;
    padding  : 0;
}

.profile {
    overflow   : hidden;
    background : #f5f5f8;
    padding    : 16px 5px;
}

.profile .name {
    display     : block;
    color       : #292929;
    float       : left;
    font-size   : 0.85vw;
    font-weight : 600;
    /*line-height : 19px;*/
    margin-left : .3vw;
    padding     : 0;
}

.profile .team {
    display     : block;
    color       : #292929;
    float       : left;
    font-size   : 0.80vw;
    margin-left : .3vw;
    padding     : 0;
}

.achievements {
    display         : block;
    justify-content : end;
    text-align      : center;
}

.profile img {
    width         : 2vmax;
    float         : left;
    border        : 1px solid #cac1c1;
    border-radius : 50%;
}

/* hide menu */
.profileNav {
    display   : none;
    clear     : both;
    margin    : 10px 0 0;
    font-size : 1.0em;
}

/* hover profile show menu */
.profile:hover .profileNav {
    display : block;
}


/*main {*/
/*    padding : 0 16px;*/
/*}*/

.pro-sidebar > .pro-sidebar-inner {
    background : #f5f5f8 !important;
    color      : #333333 !important;
}

.pro-inner-item:hover {
    background    : var(--smartpcmh-green);
    border-radius : 3px;
}

.pro-inner-item > .pro-item-content {
    font-size : 1.2em;
}

.pro-inner-item:hover > .pro-item-content {
    color : #ffffff !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
    color : #333333;
}

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item,
.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
    background : #f5f5f8 !important;
    color      : #333333 !important;

}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    color : #ffffff !important;
}


.separator {
    background : rgba(173, 173, 173, 0.2);
    height     : 1px;
}

/*.pro-menu.profileNav {*/
/*    padding : 0;*/
/*}*/

.profileNav .pro-menu-item {
    /*padding : 0px 8px;*/
}

.profileNav .pro-inner-item {
    /*padding : 0 0 0 16px;*/
}

.profileNav .pro-inner-item > .pro-item-content {
    font-size : 1.0em;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    background : #f5f5f8 !important;
}

.profileNav .pro-sidebar .pro-menu {
    padding-top : 0;
}

.profileNav .pro-menu .pro-menu-item > .pro-inner-item {
    padding : 4px 10px 4px 5px !important;
}

.profileNav .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    margin-right : 5px;
}

.notify-bubble {
    position         : absolute;
    top              : -1px;
    left             : 25px;
    /*padding          : 2px 5px 2px 6px;*/
    background-color : #d10000;
    color            : white;
    font-size        : 0.65em;
    text-align       : center;
    border-radius    : 50%;
    border           : 1px solid #ffffff;
    display          : block;
    min-width        : 2em;
    line-height      : 2em;
    cursor           : pointer;
}