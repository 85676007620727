@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url("fonts/fonts.css");
@import "react-data-grid/lib/styles.css";

/* Put global css here */

:root {
    --smartpcmh-alt-green       : rgb(138, 204, 72);
    --smartpcmh-alt-green-dark  : #74af41;
    --smartpcmh-alt-orange      : #ff9000;
    --smartpcmh-alt-orange-dark : #dc7300;
    --smartpcmh-alt-black       : #404040;

    --max-green-yellow          : #d7f75b;
    --lemon-curry               : #d19c1d;
    --colorbogus                : #d34e24;
    --dark-spring-green         : #297045;
    --lapiz-lazuli              : #2274a5;

    --smartpcmh-green           : #329946;
    --smartpcmh-green-light     : #a9d7ae;
    --smartpcmh-green-very-light: #f0fff0;
    --smartpcmh-green-dark      : #045433;
    --smartpcmh-button-border   : #528651;
    --smartpcmh-text-color      : #212529;
    --smartpcmh-line-color      : #ededf0;
    --smartpcmh-grey            : #e6e7e8;
    --smartpcmh-grey-dark       : #969798;
    
}

@font-face {
    /*font-family : 'Avenir-Roman';*/
    /*src: url('fonts/AvenirLTStd-Roman.otf') format('opentype');*/
    font-weight : normal;
    font-style  : normal;
}

html, body {
    font-family : 'Avenir-Roman', "Segoe UI", sans-serif;
    color       : var(--smartpcmh-alt-black);
    outline     : none;
    min-height  : 100%;
    height      : 100vh;
    overflow    : auto;
}


.toast-container {
    border-radius : 2px;
}

.toast-body {
    /*font-family : Avenir-Roman, "Segoe UI", sans-serif;*/
}

.container {
    margin-top : 1em
}

input.form-control, select.form-control {
    border-radius : 2px
}

::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder { /* Microsoft Edge */
    color : #707070 !important;
}

::placeholder {
    opacity : 0.5;
}

.form-control:focus, .btn:focus, li.page-item.active, li.page-item:focus {
    border-color : var(--smartpcmh-alt-green);
    box-shadow   : 0 0 0 3px rgba(138, 204, 72, 0.3)
}

.btn-secondary:focus, .btn-outline-secondary:focus {
    border-color : var(--smartpcmh-alt-orange);
    box-shadow   : 0 0 0 3px rgba(255, 144, 0, 0.3)
}

.unSelectable {
    -webkit-touch-callout : none;
    -webkit-user-select   : none;
    -khtml-user-select    : none;
    -moz-user-select      : none;
    -ms-user-select       : none;
    user-select           : none;
}

.table {
    border-radius : 2px;
    border        : 1px solid #dee2e6;
}

a, a:hover, a:visited, a:link, a:active {
    text-decoration : none;
}

a {
    color : var(--smartpcmh-alt-green);
}

a:hover {
    color : var(--smartpcmh-alt-green-dark);
}

table, th {
    vertical-align : top !important;
}

.btn, .card, .alert {
    border-radius : 2px
}

.btn-primary, .badge-primary {
    background-color : var(--smartpcmh-alt-green);
    border-color     : var(--smartpcmh-alt-green);
}

.btn-primary:hover, .btn-outline-primary:hover {
    background-color : var(--smartpcmh-alt-green-dark);
    border-color     : var(--smartpcmh-alt-green-dark);
}

.btn-outline-primary {
    border-color : var(--smartpcmh-alt-green);
    color        : var(--smartpcmh-alt-green);
}

.btn-primary[disabled] {
    background-color : #9ce14b;
    border-color     : #9ce14b;
}


.btn-secondary, .badge-secondary {
    background-color : var(--smartpcmh-alt-orange);
    border-color     : var(--smartpcmh-alt-orange);
}

.btn-secondary:hover, .btn-outline-secondary:hover {
    background-color : var(--smartpcmh-alt-orange-dark);
    border-color     : var(--smartpcmh-alt-orange-dark);
}

.btn-outline-secondary {
    border-color : var(--smartpcmh-alt-orange);
    color        : var(--smartpcmh-alt-orange);
}

h1, h2 {
    /*font-family : "Lato", sans-serif;*/
    line-height : 1.3;
}

.smartpcmh-button {
    color         : #fff;
    background    : var(--smartpcmh-green);
    display       : block;
    line-height   : 32px;
    padding       : 0 12px;
    border-radius : 6px;
    cursor        : pointer;
    margin        : 12px auto;
    box-shadow    : 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.smartpcmh-editor .textField {
    color : #e2e2e2 !important;
}

.smartpcmh-editor .focused {
    color : #434551 !important;
}

.smartpcmh-editor .textField fieldset {
    color        : #a9a9a9 !important;
    border-color : rgba(226, 226, 226, 0.75) !important;
    /*border-color: #434551 !important;*/
}

.smartpcmh-editor .textField input {
    color : #434551 !important;
}

.smartpcmh-editor select {
    /*color        : #a9a9a9;*/
    border-color  : rgba(226, 226, 226, 0.75) !important;
    display       : block;
    line-height   : 32px;
    padding       : 0 16px;
    border        : 1px solid #434551;
    border-radius : 6px;
    cursor        : pointer;
    margin        : 12px auto;
    width         : 100%;

}

.smartpcmh-editor option:disabled {
    color : #a9a9a9;
}

.smartpcmh-editor option {
    color : black;
}


.pcmh-content-header {
    margin-top    : 8px;
    margin-bottom : 8px;
    /*margin        : 8px 0;*/
    padding       : 8px;
    border-radius : 6px;
    background    : var(--smartpcmh-green-dark);
    color         : #ffffff;
    min-height    : 4em;
}

.pcmh-content-header input {
    font-family   : FontAwesome, "Lato", sans-serif;
    color         : #434551;
    display       : block;
    line-height   : 32px;
    padding       : 0 16px;
    /*border        : 1px solid #434551;*/
    border-radius : 6px;
    width         : 100%;

}

.pcmh-content-header .name {
    /*font-family : "Lato", sans-serif;*/
    font-size : 2em;
    color     : white;
}

.pcmh-content-header .icon {
    font-size : 2.5em;
    color     : white;
    cursor    : pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border                  : none !important;
    -webkit-text-fill-color : inherit !important;
    -webkit-box-shadow      : 0 0 0 1000px #ffffff inset;
    transition              : background-color 5000s ease-in-out 0s;
}

.section {
    padding : 0 10px;
    height  : 100%;
}

.MuiFormControl-root .MuiFormLabel-root.Mui-focused {
    color : rgba(169, 169, 169, 0.75);
}

a:focus,
button:focus,
input:focus,
textarea:focus,
fieldset:focus,
select:focus {
    outline : none !important;
}

.app {
    height     : 100%;
    display    : flex;
    position   : relative;
    overflow-x : hidden;
    overflow-y : hidden;
}


.tab-contents {
    height     : 100%;
    overflow-y : scroll;
}

.footer {
    position   : absolute;
    bottom     : 0px;
    background : white;
    width      : 100%;
    z-index    : 1000;
    font-size  : .85vmax;
    left       : 50%;
}

.content {
    width   : 100%;
    display : block;

}

.pcmh-content {
    height : 100%;
    margin : 0 4px;
}

.pcmh-admin {
    height : 100%;
}

.Checkbox {
    color   : var(--smartpcmh-green) !important;
    padding : 0;
}

.Checkbox.checked {
    color : var(--smartpcmh-green) !important;
}

.app .editorButton {
    background     : var(--smartpcmh-green);
    color          : white;
    padding        : 5px 6px;
    box-shadow     : 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    transition     : background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius  : 4px;
    font-weight    : 500;
    line-height    : 1.75;
    letter-spacing : 0.02857em;
    text-transform : uppercase;
    font-family    : "Roboto", "Helvetica", "Arial", sans-serif;
    font-size      : 0.875rem;
}

.app .editorButton:hover{
    background     : var(--smartpcmh-button-border);


}