.announcementbutton {
    color         : #535353;
    position      : relative;
    justify-self  : end;
    text-align    : center;
    width         : 150px;
    padding       : 8px 0;
    margin        : 0 4px;
    background    : #f5f5f8;
    border-radius : 24px;
    outline       : none;
    cursor        : pointer;
    border        : 1px solid #f5f5f8; }

.announcementbutton:hover {
    background : #ffffff;
    border     : 1px solid #f5f5f8; }

.announcementbutton .notify-bubble {
    position         : absolute;
    top              : -8px;
    left             : -7px;
    padding          : 2px 5px 2px 6px;
    background-color : #d10000;
    color            : white;
    font-size        : 0.65em;
    text-align       : center;
    border-radius    : 50%;
    border           : 1px solid #ffffff;
    display          : block;
    min-width        : 20px;
}
