.profileItem {
    margin        : 16px 0;
    padding       : 16px;
    background    : #f5f5f8;
    border-radius : 6px;
}

.profileItem .name {
    /*font-family : "Lato", sans-serif;*/
    font-size   : 1.5em;
    color       : #535353;
}

.profileItem .edit {
    font-size : 2.5em;
    color     : #535353;
    cursor    : pointer;
}

.profileItem .editing {
    width : 100%;
}


.profileItem .profile-photo img {
    width         : 100px;
    /*float         : left;*/
    border        : 1px solid #cac1c1;
    border-radius : 100px;
}

.profileItem .fieldHeader {
    /*font-family : "Lato", sans-serif;*/
    font-weight : bolder;
}

.profileItem label {
    display : block;
    padding : 0;
    margin  : 0;
}

.profileItem .reducePadding {
    padding : 0 2px;
}