.rs_module {
    margin-bottom : 16px; }

.rs_module .rs_inner {
    border-radius : 6px;
    padding       : 16px;
    background    : #f5f5f8; }

.rs_module .rs_inner h2 {
    margin        : 0;
    margin-bottom : 8px;
    padding       : 0; }

.rs_module .rs_inner .rs_graph {
    height     : 360px;
    background : white; }
