
.toast-pcmh-success {
    background-color : #8acc48;
}

.toast-pcmh-error {
    background-color : #e74c3c;
}

.toast-pcmh-warning {
    background-color : #f1c40f;
}

