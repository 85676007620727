.textField {
    color : #000 !important;
}

.focused {
    color : #434551;
}

.textField fieldset {
    color        : #a9a9a9 !important;
    border-color : rgba(226, 226, 226, 0.75) !important;
    /*border-color: #434551 !important;*/
}

.textField input {
    background-color : #ffffff !important;
}