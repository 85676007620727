.pcmh-document-metadata {
    color           : #535353;
    padding         : 0 4px;
    margin          : 1px 3px;
    border          : 1px solid #cfcfcf;
    border-radius   : 3px;
    background      : #edf3ed;
    text-decoration : none;
    box-sizing      : border-box;
    text-align      : center;
    display         : inline-block;
}

.pcmh-document-metadata .remove-item {
    cursor      : pointer;
    vert-align  : middle;
    display     : inline;
    font-size   : .90em;
    font-weight : bolder;
    position    : relative;
    top         : -1px;
}

.pcmh-document-metadata .item-name {
    padding-left : 3px;
    vert-align   : middle;
    display      : inline;
    /*font-family  : FontAwesome, "Lato", sans-serif;*/
    font-size    : .75em;
}