.form-group {
    background-color: #ffffff;
    margin: 0;
}

.form-group > label {
    background-color: #ffffff;
}

textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    overflow: auto;
    resize: vertical;

}

textarea.form-textarea {
    height: auto;
}

.form-textarea {
    display          : block;
    width            : 100%;
    height           : calc(1.5em + 0.75rem + 2px);
    padding          : 0.375rem 0.75rem;
    font-size        : 1rem;
    font-weight      : 400;
    line-height      : 1.5;
    /*color: #495057;*/
    background-color : #fff;
    background-clip  : padding-box;
    border           : 1px solid #ffffff;
    transition       : border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline          : none;
}