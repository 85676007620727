.editorHeader {
    background         : var(--smartpcmh-green);
    color              : #fff;
    /*font-family        : "Lato", sans-serif;*/
    font-weight        : bolder;
    text-align         : center;
    -webkit-box-shadow : 0 0 0 2px rgba(0, 0, 0, 0.10);
    box-shadow         : 0 0 0 2px rgba(0, 0, 0, 0.10);
    padding-bottom     : 10px;
}

.editorHeader h2 {
    color : white;
}

.textField {
    color : rgba(104, 138, 118, 0.50) !important;
}

.focused {
    color : #434551;
}

.textField fieldset {
    color        : #434551 !important;
    border-color : rgba(104, 138, 118, 0.75) !important;
    /*border-color: #434551 !important;*/
}

.profileErrors {
    color     : #ff0000;
    /*font-family : "Lato", sans-serif;*/
    font-size : 0.75em;
}



.deleteButton {
    background : red !important;
    color      : #ffffff !important;
}

.editorFooter {
    border-top : 1px solid rgba(169, 169, 169, 0.50);
}

.photo {
    border        : solid 1px rgba(104, 138, 118, 0.75) !important;
    border-radius : 4px;
    margin-top    : 7px;
    height        : 250px;
    width         : 220px;
    color         : rgba(104, 138, 118, 0.50) !important;
    margin-bottom : 7px;
}

.photo img {
    display : block;
    padding : 4px;
}

.pcmh-profile-editor .iconButton {
    background    : var(--smartpcmh-green);
    color         : white;
    font-size     : 1.7em;
    border-radius : 6px;
    padding       : 2px 8px;
    cursor        : pointer;
}

.pcmh-profile-editor .textField {
    color : #e2e2e2 !important;
}

.pcmh-profile-editor .focused {
    color : #434551 !important;
}

.pcmh-profile-editor .textField fieldset {
    color        : #a9a9a9 !important;
    border-color : rgba(226, 226, 226, 0.75) !important;
    /*border-color: #434551 !important;*/
}

.pcmh-profile-editor .textField input {
    color : #434551 !important;
}

.pcmh-profile-editor .segment {
    border        : solid 1px rgba(226, 226, 226, 0.75) !important;
    border-radius : 4px;
    margin        : 10px 0;
    padding       : 7px 2px 2px 2px;
    min-height    : 2em;
}

.pcmh-profile-editor .segment-title {
    margin     : -17px 0 0 4px;
    padding    : 0 5px;
    /*font-family : FontAwesome, "Lato", sans-serif;*/
    font-size  : 11.5px;
    color      : #e2e2e2;
    background : #fff;
    display    : block;
    position   : absolute;
}

.pcmh-profile-editor .editorHeader {
    background         : #89bf40;
    color              : #fff;
    /*font-family        : "Lato", sans-serif;*/
    font-weight        : bolder;
    text-align         : center;
    -webkit-box-shadow : 0 0 0 2px rgba(0, 0, 0, 0.10);
    box-shadow         : 0 0 0 2px rgba(0, 0, 0, 0.10);
    padding-bottom     : 10px;
}
