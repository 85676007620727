.assessment-templates {
    height   : 100%;
    position : relative;
    display  : block;
}

.assessment-templates .template-content {
    /*height        : 92%;*/
    height     : calc(100% - 5em);
    position   : absolute;
    width      : 100%;
    overflow-y : scroll;
    /*padding-right : 10px;*/
}

.templates-container .published {
    border : 2px solid var(--smartpcmh-alt-green);
}

.templates-container .inactive {
    border : 2px solid var(--colorbogus);
}

.assessment-templates .Checkbox {
    color   : var(--smartpcmh-green);
    padding : 0;
    border  : none;
    outline : none;
}

.assessment-templates .Checkbox.checked,

.assessment-templates .Checkbox:hover {
    color : var(--smartpcmh-green-dark);
}

.assessment-templates .templates-container {
    overflow-y : scroll;
    /*height        : 88vh;*/
    height     : calc(100% - 9vmax);
    width      : 100%;
    /*padding-right : 6px;*/
    position   : absolute;
    display    : block;
}

.assessment-templates .Checkbox.MuiIconButton-colorSecondary:hover {
    background-color : transparent;
}

.batch-assignment-dialog .MuiDialog-root .MuiDialog-container {
    height : 80%;

}

.MuiDialog-root.batch-assignment-dialog .MuiBackdrop-root {
    /*background-color: rgb(255, 255, 255);*/
}

.MuiDialog-root.batch-assignment-dialog .MuiDialog-paper {
    min-width : 600px;
}

.MuiDialog-root.batch-assignment-dialog-full .MuiDialog-paper {
    min-width  : 100%;
    min-height : 100%;
}

.batch-assignment-dialog-full .dialog-header,
.batch-assignment-dialog .dialog-header {
    background         : var(--smartpcmh-green);
    color              : #fff;
    /*font-family        : "Lato", sans-serif;*/
    font-weight        : bolder;
    text-align         : center;
    font-size          : 24px;
    -webkit-box-shadow : 0 0 0 2px rgba(0, 0, 0, 0.10);
    box-shadow         : 0 0 0 2px rgba(0, 0, 0, 0.10);
    padding-bottom     : 8px;
}

.batch-assignment-dialog-full .FormControlLabel > .checkbox,
.batch-assignment-dialog .FormControlLabel > .checkbox {
    /*margin-left : 14px;*/
    color   : var(--smartpcmh-alt-green-dark);
    padding : 0;
}

.assessment-templates .checkbox-label {
    font-size : 1.2vmax;
}

.assessment-templates .Checkbox-alt {
    color   : white;
    padding : 0;
    border  : none;
    outline : none;
}

.assessment-templates .Checkbox-alt.checked,
.assessment-templates .Checkbox-alt:hover {
    color : var(--smartpcmh-green-light);
}

.batch-assignment-dialog-full .FormControlLabel > input,
.batch-assignment-dialog .FormControlLabel > input {
    color   : var(--smartpcmh-alt-green-dark);
    padding : 0;
}

.batch-assignment-dialog .url {
    background    : var(--smartpcmh-grey);
    padding       : 10px 3px;
    border-radius : 4px;
    overflow-x    : scroll;
    width         : 100%;
    font-size     : 1.0vmax;
}

.batch-assignment-dialog .icon {
    font-size : 2.0em;
}

.batch-assignment-dialog .recipients {
    min-height    : 200px;
    max-height    : 200px;
    background    : var(--smartpcmh-grey);
    overflow-y    : scroll;
    margin-top    : 15px;
    border-radius : 6px;
}



.batch-assignment-dialog-full .section,
.batch-assignment-dialog .section {
    border-bottom : 1px solid #a9a9a9;
    margin-bottom : 6px;
    width         : 110%;
    margin-left   : -24px;
}

.batch-assignment-dialog-full .textField,
.batch-assignment-dialog .textField {
    color : #e2e2e2 !important;
}

.batch-assignment-dialog-full .focused,
.batch-assignment-dialog .focused {
    color : #434551 !important;
}

.batch-assignment-dialog-full .quill {
    height : 90%;
    width  : 100%;
    margin : auto;
}

.batch-assignment-dialog .error {
    border : 1px solid red;
}
.batch-assignment-dialog .error-text {
    color : red;
}
