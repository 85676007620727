.multiple-autocomplete-dialog .chip-item {
    color           : #535353;
    border          : 1px solid #cfcfcf;
    background      : #edf3ed;
    margin          : 1px 2px;
}

.multiple-autocomplete-dialog .MuiTextField-root .MuiInput-underline::after {
    border: none;
}

.multiple-autocomplete-dialog .MuiTextField-root .MuiInput-underline::before {
    border: none;
}

.multiple-autocomplete-dialog .MuiTextField-root .MuiInput-underline:hover {
    border: none;
}

.multiple-autocomplete-dialog .MuiTextField-root .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border: none;
}

.multiple-autocomplete-dialog .MuiTextField-root button:not(:disabled) {
    color: transparent;
}

.multiple-autocomplete-dialog .MuiAutocomplete-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
}