.moduleWrapper .moduleTitle {
    padding               : 16px;
    border-radius         : 6px;
    background            : #36373f;
    color                 : #ffffff;
    display               : grid;
    grid-template-columns : [first] auto [line2] 200px;
    justify-items         : start; }

.moduleWrapper .moduleTitle h2 {
    margin  : 0;
    padding : 0; }

.moduleWrapper .moduleTitle .btnHolder {
    display               : grid;
    grid-template-columns : repeat(2, auto);
    justify-self          : end; }

.moduleWrapper .moduleTitle .btnHolder a {
    color         : #ffffff;
    display       : block;
    line-height   : 32px;
    padding       : 0 16px;
    border        : 1px solid #434551;
    border-radius : 6px; }

.moduleWrapper .moduleTitle .btnHolder a:hover {
    background : #434551; }

.moduleWrapper .moduleTitle .btnHolder a:last-child {
    margin-left : 16px; }

.moduleWrapper .twocolStsuct {
    display               : grid;
    grid-template-columns : [first] auto [line2] 400px;
    justify-items         : start; }

.moduleWrapper .twocolStsuct .agendaItems {
    overflow-y : scroll;
    height     : 77vh; }

.moduleWrapper .twocolStsuct .agendaItems .completedItems {
    margin        : 16px 0;
    padding       : 16px;
    background    : #f5f5f8;
    border-radius : 6px; }

.moduleWrapper .twocolStsuct .agendaItems .completedItems h3 {
    display : block;
    margin  : 0;
    padding : 0; }

.moduleWrapper .twocolStsuct .agendaItems .uncompletedItems {
    background : #ffffff; }

.moduleWrapper .twocolStsuct .agendaItems .itemRow {
    background            : #ffffff;
    padding               : 16px;
    margin-top            : 8px;
    border                : 1px solid #dee3eb;
    border-radius         : 6px;
    display               : grid;
    grid-template-columns : repeat(3, auto);
    align-items           : center;
    justify-items         : start; }

.moduleWrapper .twocolStsuct .agendaItems .itemRow h3,
.moduleWrapper .twocolStsuct .agendaItems .itemRow p {
    margin  : 0;
    padding : 0 16px; }

.moduleWrapper .twocolStsuct .agendaItems .itemRow h3 {
    font-weight : 700;
    color       : var(--smartpcmh-green-light); }

.moduleWrapper .twocolStsuct .agendaItems .itemRow span {
    display       : block;
    width         : 35px;
    height        : 35px;
    padding-top   : 3px;
    border-radius : 35px;
    border        : 1px solid #dee3eb;
    text-align    : center; }

.moduleWrapper .twocolStsuct .agendaItems .itemRow span svg {
    fill           : var(--smartpcmh-green);
    width          : 16px;
    height         : 16px;
    display        : inline-block;
    vertical-align : middle; }

.moduleWrapper .twocolStsuct .agendaItems .itemRow span:hover {
    background : var(--smartpcmh-green); }

.moduleWrapper .twocolStsuct .agendaItems .itemRow span:hover svg {
    fill : #ffffff; }

.moduleWrapper .twocolStsuct .notesAnddocs {
    margin-left : 16px; }

.moduleWrapper .twocolStructRightAuto {
    display               : grid;
    grid-template-columns : [first] auto [line2] 550px;
    justify-items         : start;
}