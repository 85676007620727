.Select {
    width: 500px;
}

.person-assign-dialog .MuiButton-contained.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.person-assign-dialog .textField .MuiInputBase-input .disabled-option {
    color: rgba(0, 0, 0, 0.40);
}