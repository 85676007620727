/*Color for the right side control*/
.auto-complete button {
    color : #e3e3e3 !important;
}

/*Box around entire component*/
.auto-complete .textBox fieldset {
    /*color        : #434551 !important;*/
    /*border-color : #e3e3e3 !important;*/
}

/*.textBox {*/
/*    background : #c2c2c2 !important;*/
/*}*/

/*Typed text*/
.auto-complete .textBox input {
    border : none;
    color: #757575;
}

/*Label inside and little label when focused*/
.auto-complete .textBox label {
    color : rgba(169, 169, 169, 0.75);
}

.auto-complete .MuiAutocomplete-root .Mui-focused {
    color   : #e0e0e0;
    outline : 0;
}

.pcmh-content-header .search-term {
    font-family   : FontAwesome, "Lato", sans-serif;
    color         : #434551;
    display       : block;
    line-height   : 32px;
    /*padding       : 10px 10px;*/
    /*border        : 1px solid #434551;*/
    border        : none;
    border-radius : 6px;
    background    : #ffffff;
    outline       : 0;
}


#search-input input:active {
    outline : none;
    border  : none;
}

