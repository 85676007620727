
.pcmh-login .login-container {
    width         : 300px;
    height        : 350px;
    border        : 1px solid var(--smartpcmh-green);
    border-radius : 6px;
    margin        : auto;
    position      : absolute;
    top           : -50px;
    bottom        : 0;
    left          : 0;
    right         : 0;
    align-content : center;
}

.pcmh-login .card-header {
    background : var(--smartpcmh-green);
    color      : white;
}

.pcmh-login .card-footer {
    background    : white;
    border-radius : 0 0 6px 6px;

}

.pcmh-login .login-alert {
    background  : #ffffff;
    color       : #ff0000;
    font-weight : bolder;
}


.pcmh-login .achievement {
    color     : var(--smartpcmh-green);
    font-size : 1.2em;
}

.pcmh-login .achievements span {
    display : inline-block !important;
}

.MuiDialog-root.login-dialog .MuiDialog-container {
    height : 80%;
}

.MuiDialog-root.login-dialog .MuiBackdrop-root {
    background-color : rgb(255, 255, 255);
}

@keyframes shake-animation {
    0% {
        transform : translateX(0);
    }
    25% {
        transform : translateX(-10px);
    }
    50% {
        transform : translateX(10px);
    }
    75% {
        transform : translateX(-10px);
    }
    100% {
        transform : translateX(0);
    }
}
