.recipient {
    color           : #535353;
    padding         : 2px 6px;
    margin          : 1px 4px;
    border          : 1px solid #cfcfcf;
    border-radius   : 10px;
    background      : #edf3ed;
    text-decoration : none;
    box-sizing      : border-box;
    text-align      : center;
    display         : inline-block;

}

.recipient .removeRecipient {
    cursor      : pointer;
    display     : inline;
    margin-left : 4px;
}

.recipient .recipientName {
    padding-left : 3px;
    vert-align   : middle;
    display      : inline;
}