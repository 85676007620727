@import url("../../../fonts/fonts.css");

.assessment .header {
    font-family : 'Acumin Pro', sans-serif !important;
    background  : var(--smartpcmh-alt-black);
}

.assessment .header .errors {
    color       : red !important;
    font-weight : bold;
    text-align  : center;
    background  : white;
    font-size   : 1.4vw;
}

.assessment .header .completed {
    background  : var(--smartpcmh-green);
    padding     : 6px 0 6px 4px;
    font-weight : bold;
}

.assessment .header .dates {
    color     : white;
    font-size : 16px;
}

.assessment .header .dates .date-header {
    font-family : "Acumin Pro", inherit, sans-serif;
    font-weight : 500;
}

.assessment .header .dates .date-value {
    font-family : "Acumin Pro", inherit, sans-serif;
}

.assessment .header .assessment-name {
    font-family : "Acumin Pro", inherit, sans-serif;
    color       : white;
}

.assessment .header .status {
    padding            : 4px;
    border-radius      : 6px;
    -webkit-box-shadow : 4px 4px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow    : 4px 4px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow         : 4px 4px 6px 0 rgba(0, 0, 0, 0.16);
}

.assessment .header .person-name {
    font-family : "Acumin Pro", inherit, sans-serif;
    font-size   : 24px;
    color       : white !important;
}

.assessment .header .completed-assessment-icon {
    color     : var(--smartpcmh-button-border);
    font-size : 22px;

}


.assessment .FormControlLabel > span {
    font-size : 16px;
}

.assessment .FormControlLabel > .Radio {
    margin-left : 20px;
    color       : var(--smartpcmh-alt-green-dark);
    padding     : 0
}

.assessment .FormControlLabel > .Radio.read-only {
    color : var(--smartpcmh-alt-black)
}

.assessment .FormControlLabel > .Radio.checked {
    color : var(--smartpcmh-alt-green-dark);
}

.FormControlLabel > .Checkbox {
    margin-left : 20px;
    color       : var(--smartpcmh-alt-green-dark);
    padding     : 0
}

.assessment .FormControlLabel > .Checkbox.read-only {
    color : var(--smartpcmh-alt-black);
}

.FormControlLabel > .Checkbox.checked {
    color : var(--smartpcmh-alt-green-dark);
}


.assessment .assessment-contents .groups {
    position   : absolute;
    overflow-y : scroll;
    height     : 90%;
    width      : 100%;
    top        : 105px;


}

.assessment .assessment-contents .group-control {
    font-weight : bold;
    font-size   : 22px;
    padding     : 10px 2px;
}

.assessment .assessment-contents .group-control .icon {
    cursor  : pointer;
    outline : none;
}

.assessment .assessment-contents .group {
    padding       : 4px 16px;
    background    : var(--smartpcmh-line-color);
    margin        : 40px 10px;
    border-radius : 6px;
}

.assessment .assessment-contents .group .question {
    background-color : #fff !important;
    box-shadow       : 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    border           : 1px solid #dee2e6;
    border-radius    : 0.25rem !important;

}

.assessment .assessment-contents .group .read-only {
    background    : var(--smartpcmh-line-color) !important;
    box-shadow    : none !important;
    border        : none !important;
    border-radius : 0.25rem !important;

}


.assessment .assessment-contents .questions {
    margin : 2px;
}


.assessment .question-error {
    border : 2px solid rgba(255, 0, 0, .50) !important;
}


main {
    padding : 0;
}