#assignments-dialog .MuiPaper-root{
    max-height: none;
}

#assignments-dialog .MuiTableContainer-root{
    max-height: none;
}

#assignments-dialog .MuiDialogContent-root {
    border: 3px solid var(--smartpcmh-green);;
}

#assignments-dialog .dialog-title {
    background-color: var(--smartpcmh-green);
    color: #ffffff;
}

#assignments-dialog .assessments-label {
    background-color: #e0e0e0;
    font-size: 22px;
    font-weight: bold;
    border: 1px solid #aeaeae;
}

#assignments-dialog .MuiButton-root {
    color: #ffffff;
    background-color: var(--smartpcmh-green);;
}

#assignments-dialog .MuiButton-root:hover {
    color: #ffffff;
    background-color: #588e03;
}

#assignments-dialog .not-started {
    background-color: rgba(244, 67, 54, 0.5);
}

#assignments-dialog .not-started:hover {
    background-color: rgba(244, 67, 54, 0.5);
}

#assignments-dialog .in-progress {
    background-color: rgba(255, 238, 88, 0.5);
}

#assignments-dialog .in-progress:hover {
    background-color: rgba(255, 238, 88, 0.5);
}

#assignments-dialog .cursor-pointer {
    cursor: pointer;
}

#assignments-dialog .completed {
    background-color: #aed581;
}

#assignments-dialog .completed:hover {
    background-color: #aed581;
}

.assignments-table a {
    color: #007bff;
}

.assignments-table a:hover {
    color: #0050cb;
}

.assignments-table a:focus {
    outline: none;
}

.assignments-table .discard{
    outline: none;
    cursor: pointer;
    color: #e53935;
}

#assignable-dialog .edit {
    color: #007bff;
}
#assignable-dialog .edit:hover {
    color: #0050cb;
}
#assignable-dialog .edit:focus {
    outline: none;
}

.btns-assignable-dialog .discard {
    background-color: #e53935 !important;
    color      : #ffffff !important;
}