.dataset-admin {
    height   : 82vh;
    position : relative;
}

.dataset-admin svg {
    vertical-align : baseline;
}

.dataset-admin .dataset-box {
    margin         : 8px 0;
    padding        : 8px;
    background     : #f5f5f8;
    border-radius  : 6px;
    /*height         : 80px;*/
    vertical-align : middle;

}

.dataset-admin .datasets-container {
    overflow-y    : auto;
    height        : 85vh;
    padding-right : 6px;
    position      : relative;
}

.dataset-admin .dataset-box .header {
    font-size   : 1.2em;
    font-weight : bold;
    color       : var(--smartpcmh-green);

}

.dataset-admin .dataset-box .name {
    /*font-family : "Lato", sans-serif;*/
    font-size : 1.2vw;
    color     : #535353;
}

.dataset-admin .dataset-box .edit {
    font-size : 1.5vw;
    color     : #535353;
    cursor    : pointer;
}

.dataset-editor .divider {
    border-top    : 1px solid rgba(169, 169, 169, 0.75);
    margin-top    : 2px;
    margin-bottom : 2px;
}

.column {
    /*align-items: center;*/
    border        : 1px solid lightgrey;
    border-radius : 5px;
    padding       : 2px 0;
    margin-top    : 1px;
    margin-bottom : 2px;
    /*height: 30px;*/
}

.highlighted {
    box-shadow : rgba(50, 153, 70, 0.60) 0 0 8px 2px;
    border     : 1px solid var(--smartpcmh-green)
}

.dataset-editor .element-reorder {

}

.dataset-editor .element-text {
    vertical-align : middle;
    font-size      : 1.2vw;
}

.dataset-editor .element-actions {
    vertical-align : middle;
}

.dataset-editor svg {
    vertical-align : middle;

}

.dataset-editor .edit {
    font-size : 1.5vw;
    color     : #535353;
    cursor    : pointer;
}

.column-editor {

    border        : 1px solid #535353;
    border-radius : 6px;
    padding-top   : 5px;

}

.column-editor button {
    margin : 2px;

}


.column-editor .outer-box {
    border     : 1px solid var(--smartpcmh-grey-dark);
    height     : 273px;
    margin-top : 6px;
    padding    : 0 14px;
    background : var(--smartpcmh-grey);
}

.column-editor .inner-box {
    border     : 1px solid var(--smartpcmh-grey-dark);
    height     : 215px;
    margin-top : 6px;
    background : white;
}

.column-editor .attribute {
    border        : 1px solid var(--smartpcmh-grey-dark);
    margin-top    : 1px;
    margin-bottom : 1px;
    border-radius : 3px;
}

.column-editor .attribute-name {
    font-size : 1.0vmax;
}

.column-editor .transfer {
    text-align   : center;
    border-left  : 1px solid var(--smartpcmh-grey-dark);
    border-right : 1px solid var(--smartpcmh-grey-dark);
    height       : 100%;
}

.column-editor .filter-item {
    border        : 1px solid var(--smartpcmh-grey-dark);
    border-radius : 3px;
    margin-top    : 2px;
    margin-bottom : 2px;
    background: white;
    /*height: 50px;*/
}

.Checkbox-alt {
    color   : var(--smartpcmh-green) !important;
    padding : 0;
    border  : none;
    outline : none;
}

.Checkbox-alt.checked,
.Checkbox-alt:hover {
    color : var(--smartpcmh-green);
}

.column-editor .filter-group {
    border  : 1px solid var(--smartpcmh-grey-dark);
    border-radius: 3px;
    margin  : 10px 0 10px 0;
    padding : 0 4px;
    background: var(--smartpcmh-grey);
    box-shadow : rgba(80, 80, 80, 0.60) 2px 2px 2px;

}
.column-editor .filter-group-single {
    margin  : 10px 0 0 0;
    padding : 0 4px;
    background: white;
}