.announcement-list {
    height     : 100%;
    width      : 100%;
    overflow-x : hidden;
}

.messages,
.senders {
    /*border: 1px solid #000000;*/
    height     : 100%;
    display    : block;
    overflow-y : scroll;
    overflow-x : hidden;
}


.message-item {
    border        : 1px solid rgba(173, 173, 173, 0.2);
    /*padding: 20px;*/

    min-width     : 300px !important;
    margin        : 2px;
    border-radius : 6px;
    padding       : 0 4px 0 4px

}

.message-item:hover {
    background : #eeeeee;
    cursor     : pointer;
}

.sender-item {
    border        : 1px solid rgba(173, 173, 173, 0.2);
    border-radius : 3px;
    margin        : 1px 3px 3px 0;

}

.active {
    background : var(--smartpcmh-green-light);
}

.sender-item img {
    width         : 2vmax;
    float         : left;
    border        : 1px solid #cac1c1;
    border-radius : 30%;
    margin        : 2px
}

.sender-item .sender-name {
    font-weight : bold;
}

.sender-item .unread-count {
    font-size : .8em;
    margin    : auto;
}

.sender-item .sent-date {
    font-size : .5em;
}


.message-item .name {
    overflow      : hidden;
    white-space   : nowrap;
    text-overflow : ellipsis;
    font-size     : 12px;
    padding-top   : 0;

}

.message-item .read {
    font-weight : bold;
}

.message-item .date {
    font-size   : .7em;
    font-weight : lighter;
    text-align  : right;
    color       : rgba(53, 53, 53, 1);
    /*padding-right : 2px;*/
}

.message-item .subject {
    overflow      : hidden;
    white-space   : nowrap;
    text-overflow : ellipsis;
    /*width         : 100%;*/
    color         : rgba(53, 53, 53, 1);
    font-size     : .9em;
}

.message-item .text {
    /*overflow      : hidden;*/
    /*white-space   : nowrap;*/
    /*text-overflow : ellipsis;*/
    width       : 100%;
    color       : rgba(53, 53, 53, .8);
    font-weight : lighter;
    font-size   : .8em;
}

.message-item .delete-icon {
    font-size     : 20pt;
    margin-top    : auto;
    margin-bottom : auto;
}

.MuiDialog-root {
    position : absolute;
    left     : 100px;
}