.tm_profileCard {
    background    : white;
    /*flex-basis    : 48.3%;*/
    /*flex-basis    : auto;*/
    padding       : 8px;
    margin : 15px 0;
    border-radius : 6px;
    transition    : 0.5s; 
    width: 225px;
    height: 224px;
    display: inline-flex !important;
}

.tm_profileCard:hover {
    background : #d8edbb;
    cursor     : pointer; }

.tm_profileCard .tm_profileCardInner {
    background    : #ffffff;
    padding       : 32px 16px;
    display       : grid;
    justify-items : center; }

.tm_profileCard .tm_profileCardInner .th_profileCardInfo h3,
.tm_profileCard .tm_profileCardInner .th_profileCardInfo p,
.tm_profileCard .tm_profileCardInner .th_profileCardInfo span {
    display    : block;
    margin     : 0;
    padding    : 0;
    text-align : center;
    font-size  : .75em;}

.tm_profileCard .tm_profileCardInner .th_profileCardInfo h3 {
    color     : var(--smartpcmh-green-light);
    font-size : 1.0em;
}

.tm_profileCard .tm_profileCardInner .th_profileCardInfo a {
    color : #36373f; }

.tm_profileCard .tm_profileCardInner .th_profileCardInfo span {
    color         : #4f504b;
    font-size     : 13px;
    line-height   : 24px;
    border        : 1px solid #f5f5f8;
    border-radius : 20px;
    margin-top    : 16px; }

.tm_profileCard .tm_profileCardInner .tm_profileCardPic {
    display       : block;
    overflow      : hidden;
    width         : 55px;
    height        : 55px;
    border-radius : 30px;
    margin-bottom : 8px; }

.tm_profileCard .tm_profileCardInner .tm_profileCardPic img {
    display : block;
    height  : 55px; }
