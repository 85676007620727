.message {
    background    : white;
    min-width     : 200px;
    border-radius : 6px;
    overflow      : paged-y;
    box-sizing    : border-box;
    border        : 1px solid rgba(53, 53, 53, .3);
    padding       : 0;
    box-shadow    : 2px 3px 3px rgba(53, 53, 53, .5);
    text-align    : left;
    margin        : 8px 50px 0 0;
}

.message .header {
    background    : white;
    margin        : 0;
    border-bottom : 1px solid rgba(53, 53, 53, .7);
}

.message .sender {
    font-weight : bold;
    font-size   : 9pt;
    margin      : 0;
    padding     : 0 6px;
}

.message .subject {
    font-weight : bold;
    font-size   : 8pt;
    color       : rgba(53, 53, 53, .7);
    padding     : 0 6px;

}

.message .date {
    font-size    : 7pt;
    color        : rgba(53, 53, 53, .7);
    margin-right : 2px;
    padding      : 0 6px;
}

.message .message-body {
    margin     : 2px;
    font-size  : .8em;
    /*max-width: 225px;*/
    max-height : 300px;
    text-align : left;
    overflow-y : scroll;
    padding    : 0 4px;

}