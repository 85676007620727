.tabLink  {
    color           : #535353;
    padding         : 8px 16px;
    margin          : 0 4px;
    border          : 1px solid #f5f5f8;
    border-radius   : 24px;
    text-decoration : none;
    cursor          : pointer;
    box-sizing      : border-box;
}

.tabLink.active {
    color      : #ffffff;
    background : var(--smartpcmh-green);
}

.tabLink:hover {
    background : var(--smartpcmh-green);
    color      : #ffffff; }

