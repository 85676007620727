.curriculums {
    height: 82vh;
    position: relative;
}

.curriculums .template-content {
    height: 88%;
    position: absolute;
    width: 100%;
    overflow-y: auto;
    padding-right: 10px;
}

.curriculums .FormControlLabel > .Checkbox {
    color   : var(--smartpcmh-green);
    padding : 0
}

.curriculums .FormControlLabel > .Checkbox.checked {
    color : var(--smartpcmh-green);
}

.curriculums .curriculum-box {
    margin        : 16px 0;
    padding       : 16px;
    background    : #f5f5f8;
    border-radius : 6px;
}

.curriculums .curriculum-box .name {
    /*font-family : "Lato", sans-serif;*/
    font-size   : 1.5em;
    color       : #535353;
}

.curriculums .curriculum-box .edit {
    font-size : 2.5em;
    color     : #535353;
    cursor    : pointer;
}

.curriculums .curriculum-box .editing {
    width : 100%;
}

.curriculums .curriculum-box .photo {
    width  : 100px;
    height : 100px;
}

.curriculums .curriculum-box .fieldHeader {
    font-family : "Lato", sans-serif;
    font-weight : bolder;
}

.curriculums .curriculum-box label {
    display : block;
    padding : 0;
    margin  : 0;
}

.curriculums .curriculum-box .reducePadding {
    padding : 0 2px;
}

.curriculums .curriculum-box .trainings {
    background    : #4da965;
    border-radius : 50%;
    border        : 1px solid #ffffff;
    display       : inline-block;
    color         : white;
    font-size     : 1.0em;
    text-align    : center;
    min-width     : 50px;
    margin-left   : 20px;
}

.curriculums .curriculum-box .trainings:before,
.curriculums .curriculum-box .trainings:after {
    content        : '\200B';
    display        : inline-block;
    line-height    : 0;
    padding-top    : 50%;
    padding-bottom : 50%;
}

.curriculums .curriculum-box .trainings:before {
    padding-left : 8px;
}

.curriculums .curriculum-box .trainings:after {
    padding-right : 8px;
}

.curriculums .curriculum-box .trainings:after {
    padding-right : 8px;
}

.radio {
    margin-left : 20px;
    color       : var(--smartpcmh-alt-green-dark) !important;
    padding     : 0
}
