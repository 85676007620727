.pcmh-profile-photo {
    /*width      : 100%;*/
    max-height            : 214px;
    -webkit-border-radius : 6px;-moz-border-radius : 6px;border-radius : 6px;

}

.pcmh-profile-demographics {
    font-size : .75em;
}

.pcmh-profile-card {
    -webkit-border-radius : 6px;-moz-border-radius : 6px;border-radius : 6px;
    border-color          : var(--smartpcmh-green);
    /*font-family : "Lato", sans-serif;*/
    background            : #f9f9f9;
    height                : 400px;
}

.pcmh-profile-card-header {
    background : var(--smartpcmh-green);
    color      : white;
    font-size  : 1.3em;

}

.pcmh-profile-card-body {
    overflow-y : auto;
}

.pcmh-reading-item {
    border             : solid 1px #a9a9a9;
    /*background: #ccf1d0;*/
    background         : var(--smartpcmh-green-light);
    border-radius      : 6px;
    padding            : 3px;
    -webkit-box-shadow : 0 0 0 2px rgba(0, 0, 0, 0.10);
    box-shadow         : 0 0 0 2px rgba(0, 0, 0, 0.10);
    margin-bottom      : 5px;
}

.pcmh-reading-item:hover {
    -webkit-box-shadow : 0 0 0 4px rgba(0, 0, 0, 0.10);
    box-shadow         : 0 0 0 4px rgba(0, 0, 0, 0.10);
    border             : solid 2px #a9a9a9;
    transition         : .5s;
}

.pcmh-reading-item .title {
    /*font-family: "Lato", sans-serif;*/
    font-size   : 1.5em;
    font-weight : bold;
    cursor      : pointer;

}

.pcmh-reading-item .badge {
    /*overflow   : hidden;*/
    /*border-radius: 40px;*/
}

.pcmh-rater .rate {
    color     : var(--smartpcmh-green);
    font-size : 2.0em;
    cursor    : pointer;
}

.pcmh-rater .rate span {
    display : inline-block;

}

.pcmh-rater .rates {
    display : block;
}
