.pcmh-content-admin {
}

.pcmh-content-admin .textField,
.pcmh-content-uploader .textField {
    color : #e2e2e2 !important;
}

.pcmh-content-admin .focused,
.pcmh-content-uploader .focused {
    color : #434551 !important;
}

.pcmh-content-admin .textField fieldset,
.pcmh-content-uploader .textField fieldset {
    color        : #a9a9a9 !important;
    border-color : rgba(226, 226, 226, 0.75) !important;
    /*border-color: #434551 !important;*/
}

.pcmh-content-admin .textField input,
.pcmh-content-uploader .textField input {
    color : #434551 !important;
}

.pcmh-content-admin .header {
    margin        : 8px 0;
    padding       : 8px;
    border-radius : 6px;
    background    : #36373f;
    color         : #ffffff;

    justify-items : start;
}

.pcmh-content-admin .header .name {
    margin      : 0;
    font-weight : bold;
    line-height : 1.3;
    font-size   : 2.0em;
    color       : #a9a9a9;
    /*font-family : "Lato", sans-serif;*/
}

.pcmh-content-admin .header input {
    /*font-family   : FontAwesome, "Lato", sans-serif;*/
    color         : #434551;
    display       : block;
    line-height   : 30px;
    padding       : 0 16px;
    border        : 1px solid #434551;
    border-radius : 6px;
    width         : 100%;
}

.pcmh-content-admin .icon,
.pcmh-content-uploader .icon {
    font-size   : 2.0em;
    color       : #a9a9a9;
    cursor      : pointer;
    margin-left : -20px;
}


.pcmh-content-uploader .documentUpload {
    height        : 253px;
    width         : 192px;
    border        : solid 1px;
    border-color  : rgba(226, 226, 226, 0.75) !important;
    border-radius : 4px;
    display       : block;
    margin        : 7px 10px 7px 0;
}

.pcmh-content-uploader .editorFooter {
    border-top      : 1px solid rgba(226, 226, 226, 0.50);
    justify-content : flex-end;
}



.contentDeleteButton {
    background : red !important;
    color      : #ffffff !important;
    margin     : 0 5px !important; 
}

.pcmh-content-uploader .metadata {
    background    : #a9a9a9;
    color         : #434551;
    line-height   : 1.2em;
    /*font-family   : FontAwesome, "Lato", sans-serif;*/
    border-radius : 5px;
    display       : inline-flex;
    margin        : 2px;
    padding       : 2px;
}

.segment {
    border        : solid 1px rgba(226, 226, 226, 0.75) !important;
    border-radius : 4px;
    margin        : 10px 0;
    padding       : 7px 2px 2px 2px;
}
.badge {
   width: 125px;
    height: 125px;
    margin-top: 20px;
}
.segment-title {
    margin      : -17px 0 0 4px;
    padding     : 0 5px;
    /*font-family : FontAwesome, "Lato", sans-serif;*/
    font-size   : 11.5px;
    color       : #e2e2e2;
    background  : #fff;
    display     : block;
    position    : absolute;
}

.empty-document {
    color     : rgba(226, 226, 226, 0.75) !important;
    font-size : 150px;
}

.content-viewer {
    height : 250px;
    width  : 190px;
}

.content-display {
    margin        : 2px;
    border-color  : var(--smartpcmh-green) !important;
    border-radius : calc(0.32rem - 1px) calc(0.32rem - 1px) 0 0;
    cursor        : pointer;
}


.content-title {
    /*font-family : FontAwesome, "Lato", sans-serif;*/
    font-weight : bold;
    margin      : auto;
    text-align  : center;
}

.content-metadata {
    font-size : .50em;

}

.content-metadata-title {
    display     : block;
    font-weight : bold;
    font-size   : .70em;
    padding     : 0;
    margin-top  : 8px;
}

.content-footer {
    background  : white;
    padding     : 3px;
    line-height : .80em;
}

.content-metadata-item {
    font-size : .60em;
    display   : inline-block;
    padding   : 0;
}

.pcmh-file-display-header {
    background : var(--smartpcmh-green) !important;
}

.submitContentDisabled {
    color: red;
    cursor: not-allowed;
    opacity: .5;
}