.pcmh-today-container {
    position   : relative;
    width      : 100%;
    margin     : auto;
    overflow-y : auto;
    height     : 85vh;
    font-size  : 13px; }

.pcmh-today-container table {
    /*width           : 100%;*/
    min-width       : 1280px;
    /*margin          : auto;*/
    border-collapse : separate;
    border-spacing  : 0 4px; }

.pcmh-today-container table tr {
    height     : 60px;
    min-height : 60px;
    transition : 0.5s;
}

.pcmh-today-container table tr:hover {
    background : #f3eb77 !important; }

.pcmh-today-container table tr:nth-child(odd) {
    background-color : #f5f5f8; }

.pcmh-today-container table tr:nth-child(even) {
    border-right     : solid 1px #fff;
    background-color : #fafafa; }

.pcmh-today-container table th,
.pcmh-today-container table tr {
    text-align  : center;
    padding     : 0 20px;
    white-space : nowrap;
}

.pcmh-today-container table th:first-child {
    font-size                     : 14px;
    text-align                    : left;
    padding                       : 6px 10px;
    background-color              : #ededf0;
    border-right                  : 1px solid #ffffff;
    position                      : -webkit-sticky;
    position                      : sticky;
    left                          : 0;
    z-index                       : 2;
    display                       : block;
    height                        : 90px;
    width                         : 280px;
    -moz-border-radius-topleft    : 6px;
    -moz-border-radius-bottomleft : 6px;
    border-top-left-radius        : 6px;
    border-bottom-left-radius     : 6px; }

.pcmh-today-container table th:first-child a {
    color : #3b3b3b; }

.pcmh-today-container table th:first-child a:hover {
    color : var(--smartpcmh-green); }

.pcmh-today-container table th:first-child span {
    color       : #4f504b;
    display     : block;
    font-size   : 13px;
    font-weight : 400; }

.pcmh-today-container table thead th {
    height       : 35px !important;
    background   : var(--smartpcmh-green);
    color        : white;
    position     : -webkit-sticky;
    position     : sticky;
    top          : 0;
    border-right : solid 1px #fff;
}

.pcmh-today-container table thead th {
    padding-top : 20px;
}


.pcmh-today-container table thead th:last-child {
    -moz-border-radius-topright    : 6px;
    -moz-border-radius-bottomright : 6px;
    border-top-right-radius        : 6px;
    border-bottom-right-radius     : 6px; }

.pcmh-today-container table thead th:first-child {
    background   : var(--smartpcmh-green);
    color       : #ffffff;
    padding-top : 18px;
    height      : 60px !important;
    z-index     : 5;
    -moz-border-radius-topleft    : 6px;
    -moz-border-radius-bottomleft : 6px;
    border-top-left-radius        : 6px;
    border-bottom-left-radius     : 6px;


}

.pcmh-today-container table tbody tr:first-child,
.pcmh-today-container table tbody td:first-child {
    -moz-border-radius-topleft    : 6px;
    -moz-border-radius-bottomleft : 6px;
    border-top-left-radius        : 6px;
    border-bottom-left-radius     : 6px;
}

.pcmh-today-container table tbody tr:last-child,
.pcmh-today-container table tbody td:last-child {
    -moz-border-radius-topright    : 6px;
    -moz-border-radius-bottomright : 6px;
    border-top-right-radius        : 6px;
    border-bottom-right-radius     : 6px; }

.pcmh-today-container table tbody td {
    border-right : 1px solid #ffffff;
}

.pcmh-today-container table th:first-child .pcmh-today-name {
    font-weight: bolder;
    font-size: .8vw;
}
.pcmh-today-container table th:first-child .pcmh-today-name:hover {
    color  : var(--smartpcmh-green);
    cursor : pointer;
}

.pcmh-today-container table th:first-child .pcmh-today-identifiers {
    font-size : .7vw;
}

.pcmh-today-container table tr .time {
    font-size : 11px;
    width     : 30px;
    margin    : 0;
}

.pcmh-today-container th .available {
    margin     : auto !important;
    text-align : center;
}

.risk-score {
    padding        : 2px;
    width          : 36px;
    height         : 36px;
    border-radius  : 50%;
    text-align     : center;
    vertical-align : middle;
    line-height    : 32px;
    font-size      : 16px;}