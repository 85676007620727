.pcmh-nextday-container {
    width      : 100%;
    font-size  : 0.875em;
    margin-top : 10px;
    overflow-y : scroll;
    height     : calc(100vh - 40px);

    /*border-spacing : 0px 4px; */
}

.pcmh-nextday-container table {
    width : 100%;
}

.pcmh-nextday-container table thead tr th {
    color         : #ffffff;
    background    : #36373f !important;
    height        : 50px;
    padding-top   : 16px;
    padding-left  : 5px;
    padding-right : 5px;
    text-align    : center;
    border-right  : solid 1px #ffffff;
}

.pcmh-nextday-container table thead tr th:first-child {
    -moz-border-radius-topleft    : 6px;
    -moz-border-radius-bottomleft : 6px;
    border-top-left-radius        : 6px;
    border-bottom-left-radius     : 6px;
}

.pcmh-nextday-container table thead tr th:last-child {
    -moz-border-radius-topright    : 6px;
    -moz-border-radius-bottomright : 6px;
    border-top-right-radius        : 6px;
    border-bottom-right-radius     : 6px;
}


.pcmh-nextday-container table tr:nth-child(odd) {
    background-color : #f5f5f8; }

.pcmh-nextday-container table tr:nth-child(even) {
    border-right     : solid 1px #fff;
    background-color : #fafafa; }

.pcmh-nextday-container table tr {
    height     : 50px;
    margin-top : 6px;
    transition : 0.5s;
}

.pcmh-nextday-container table tr td:first-child {
    -moz-border-radius-topleft    : 6px;
    -moz-border-radius-bottomleft : 6px;
    border-top-left-radius        : 6px;
    border-bottom-left-radius     : 6px;
}

.pcmh-nextday-container table tr td:last-child {
    -moz-border-radius-topright    : 6px;
    -moz-border-radius-bottomright : 6px;
    border-top-right-radius        : 6px;
    border-bottom-right-radius     : 6px;
}

.pcmh-nextday-container table tr td:nth-child(1) {
    width        : 25%;
    text-align   : left;
    padding-left : 6px;
}

.pcmh-nextday-container table tr td:nth-child(2) {
    width      : 10%;
    text-align : center;
}

.pcmh-nextday-container table tr td:nth-child(3) {
    width      : 10%;
    text-align : center;
}

.pcmh-nextday-container table tr td:nth-child(4) {
    width      : 15%;
    text-align : center;
}

.pcmh-nextday-container table tr td:nth-child(5) {
    width      : 20%;
    text-align : center;
}

.pcmh-nextday-container table tr td:nth-child(6) {
    width        : 20%;
    text-align   : left;
    padding-left : 6px;
}

/*
.pcmh-nextday-container table thead {
    -moz-border-radius-topleft    : 6px;
    -moz-border-radius-bottomleft : 6px;
    border-top-left-radius        : 6px;
    border-bottom-left-radius     : 6px;    
}
.pcmh-nextday-container table thead tr 
{
    background : #36373f !important;
    color      : #ffffff;
    -moz-border-radius-topleft    : 6px;
    -moz-border-radius-bottomleft : 6px;
    border-top-left-radius        : 6px;
    border-bottom-left-radius     : 6px;    
}

.pcmh-nextday-container table thead tr:hover {
    background : #36373f !important; }


.pcmh-nextday-container table thead th {
    text-align   : left;
    padding : 16px 4px 0 4px;
    border-right : solid 1px #fff;
    height     : 50px; 
}

.pcmh-nextday-container table thead th:first-child {
    -moz-border-radius-topleft    : 6px;
    -moz-border-radius-bottomleft : 6px;
    border-top-left-radius        : 6px;
    border-bottom-left-radius     : 6px; 
}

.pcmh-nextday-container table thead th:last-child {
    border-radius : 0 6px 6px 0; }

.pcmh-nextday-container table thead th:only-child {
    border-radius : 6px 6px 6px 6px; }

.pcmh-nextday-container table tbody tr td:first-child {
    -moz-border-radius-topleft    : 6px;
    -moz-border-radius-bottomleft : 6px;
    border-top-left-radius        : 6px;
    border-bottom-left-radius     : 6px; }

.pcmh-nextday-container table tbody tr td:last-child {
    -moz-border-radius-topright    : 6px;
    -moz-border-radius-bottomright : 6px;
    border-top-right-radius        : 6px;
    border-bottom-right-radius     : 6px; }

.pcmh-nextday-container table tbody tr:first-child,
.pcmh-nextday-container table tbody td:first-child {
    -moz-border-radius-topleft    : 6px;
    -moz-border-radius-bottomleft : 6px;
    border-top-left-radius        : 6px;
    border-bottom-left-radius     : 6px;
}

.pcmh-nextday-container table tbody tr:last-child,
.pcmh-nextday-container table tbody td:last-child {
    -moz-border-radius-topright    : 6px;
    -moz-border-radius-bottomright : 6px;
    border-top-right-radius        : 6px;
    border-bottom-right-radius     : 6px; }

.pcmh-nextday-container table tbody td {
    border-right : 1px solid #ffffff;
}

*/

