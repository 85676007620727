.admin-header .MuiPagination-root .MuiPaginationItem-root {
    color   : #ffffff;
    outline : none;
}

.admin-header .MuiPagination-root .Mui-selected {
    background-color : var(--smartpcmh-green) !important;
    outline          : none;
}

.admin-header {
    margin-top    : 2px;
    margin-bottom : 2px;
    /*margin        : 8px 0;*/
    padding       : 6px;
    border-radius : 6px;
    background    : var(--smartpcmh-green-dark);
    color         : #ffffff;
    min-height    : 4em;
}

.checkbox-label {
    font-size : 1vmax !important;
}

.admin-header .search-term {
    font-family   : FontAwesome, "Lato", sans-serif;
    color         : #434551;
    display       : block;
    line-height   : 32px;
    padding       : 0 8px;
    border        : 1px solid #434551;
    border-radius : 6px;
    height        : 32px;
    width         : 100%;
}

.admin-header .pagination {
    padding-right : 0;
    padding-left  : 0;
}

@media (min-width: 1200px) {
    .admin-header .search-term {
        width: 100%;
    }
}

.admin-header .name {
    font-size : 2.0vmax;
    color     : white;
}

.admin-header .icon {
    font-size : 2.5vmax;
    color     : white;
    cursor    : pointer;
}



