.pcmh-file-reader {
    width      : 100% !important;
    height     : 100% !important;
    max-width  : 100% !important;
    max-height : 100% !important;
    margin     : auto !important;

}

.pcmh-file-reader .modal-content {
    height: 100% ;
    min-height : 100vh;
}

.pcmh-file-reader .modal-title {
    color : white;

}

.pcmh-file-reader .modal-header {
    background : #090909;
    text-align : left;
}

.pcmh-file-reader .modal-body {
    height   : calc(100% - 82px);
    height   : -webkit-calc(100% - 82px);
    height   : -moz-calc(100% - 82px);
    overflow : scroll;
}

.pcmh-file-reader .no-select {
    user-select         : none;
    -moz-user-select    : none;
    -khtml-user-select  : none;
    -webkit-user-select : none;
    -o-user-select      : none;
}

.pcmh-file-reader .page-control {
    font-size           : 1.4em;
    font-weight         : bold;
    margin              : 0 2px;
    color               : #a9a9a9;
    display             : inline-block;
    user-select         : none;
    -moz-user-select    : none;
    -khtml-user-select  : none;
    -webkit-user-select : none;
    -o-user-select      : none;
}

.pcmh-file-reader .page-button {
    cursor  : pointer;
    display : inline-block;
    margin  : 0 2px 0 2px;
    color   : #a9a9a9;

}

.pcmh-file-reader .page-zoom {
    cursor      : pointer;
    display     : inline-block;
    font-size   : 2em;
    font-weight : bold;
    margin      : 0 2px 0 2px;
    color       : #a9a9a9;
}

.pcmh-file-reader .page-exit {
    cursor      : pointer;
    display     : inline-block;
    font-size   : 2em;
    font-weight : bold;
    color       : #a9a9a9;
}

.pcmh-file-reader .page-number {
    cursor    : text;
    font-size : 1.4em;
    display   : inline-block;
    margin    : 2px 2px 0 2px;
}

.pcmh-file-reader .of {
    font-size : 1.2em;
    margin    : 0 6px;
    display   : inline-block;
}


.pcmh-file-reader .page-number input {
    text-align  : right;
    font-size   : 1em;
    display     : inline-block;
    width       : 3em;
    line-height : 1em;
}

.pcmh-file-reader .file-contents {
    overflow : scroll;
    height   : 100%;
}