
.send-announcement .member-list {
    display       : block;
    min-height    : 100px;
    height        : 72px;
    color         : #000 !important;
    border        : 1px solid var(--smartpcmh-alt-green);
    border-radius : 3px;
    background    : #fff;
    border-color  : rgba(226, 226, 226, 0.75) !important;
    padding       : 2px;
    margin-top    : 3px;
}

.send-announcement .member-list .title {
    color      : #434551 !important;
    font-size  : 12px;
    background : white;
    position   : relative;
    width      : 60px;
    top        : -12px;
    left       : 7px;
    z-index    : 15000;
    overflow   : visible;
    margin     : 2px 2px 2px 0;
    padding    : 0 0 0 4px;
}

.send-announcement .member-list .sent-to {
    overflow-y : scroll;
    display    : inline-block;
}

.send-announcement .textField {
    color : #e2e2e2 !important;
}

.send-announcement .focused {
    color : #434551 !important;
}

.send-announcement label {
    color : #434551 !important;
}

.send-announcement .textField input {
    color : #434551 !important;
}

.send-announcement .textField textarea {
    color : #434551 !important;
}

.send-announcement .textField fieldset {
    color        : #a9a9a9 !important;
    border-color : rgba(226, 226, 226, 0.75) !important;
}

.send-announcement .editorHeader {
    background         : #89bf40;
    color              : #fff;
    /*font-family        : "Lato", sans-serif;*/
    font-weight        : bolder;
    text-align         : center;
    font-size          : 24px;
    -webkit-box-shadow : 0 0 0 2px rgba(0, 0, 0, 0.10);
    box-shadow         : 0 0 0 2px rgba(0, 0, 0, 0.10);
    padding-bottom     : 16px;
}

.send-announcement .editorHeader h2 {
    color : white;
}

.found-members {
    background    : white;
    min-width     : 250px;
    border-radius : 6px;
    overflow      : paged-y;
    box-sizing    : border-box;
    border        : 1px solid rgba(53, 53, 53, .5);
    box-shadow    : 2px 3px 3px rgba(53, 53, 53, .5);
    text-align    : left;
    padding       : 0 2px 0 2px;
    margin        : 0;
}

.found-members svg {
    margin : 5px;
}