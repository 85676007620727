.health-data {
    height   : 82vh;
    position : relative;
}

.health-data svg { vertical-align : baseline; }

.health-data .type-class-box {
    margin         : 16px 0;
    padding        : 16px;
    background     : #f5f5f8;
    border-radius  : 6px;
    /*height         : 80px;*/
    vertical-align : middle;

}

.health-data .type-class-box .header {
    font-size: 1.2em;
    font-weight: bold;
    color   : var(--smartpcmh-green);

}
.health-data .type-class-box .name {
    /*font-family : "Lato", sans-serif;*/
    font-size : 1.5em;
    color     : #535353;
}

.health-data .type-class-box .edit {
    font-size : 2.5em;
    color     : #535353;
    cursor    : pointer;
}

.health-data .type-class-elements {
    margin        : 0 0 0 6px;
    padding       : 0 15px;
    background    : #fcfcff;
    border-radius : 6px;
    /*border: 1px solid  rgba(33, 37, 41, 0.3);*/
    height        : 120px;
    box-shadow    : 3px 3px 6px 3px rgba(33, 37, 41, 0.3);
}

.health-data .type-class-elements .attributes {
    font-weight : bold;
    font-size   : 1.2em;
}

.health-data .type-class-elements .header {
    /*border-bottom: 1px solid  rgba(33, 37, 41, 0.3);*/
    border-top    : 1px solid rgba(33, 37, 41, 0.3);
    border-bottom : 1px solid rgba(33, 37, 41, 0.3);
}

.health-data .type-class-elements .header-col {
    font-weight : bold;
}

.health-data .type-class-elements .col-first {
    border-right : 1px solid rgba(33, 37, 41, 0.3);
}

.health-data .type-class-elements .col-middle {
    border-right : 1px solid rgba(33, 37, 41, 0.3);
    border-left  : 1px solid rgba(33, 37, 41, 0.3);
}

.health-data .type-class-elements .col-last {
    border-left : 1px solid rgba(33, 37, 41, 0.3);
}

.health-data .type-class-elements .icon {
    cursor      : pointer;
    font-weight : bold;
    color       : #535353;
    font-size   : 1.2em;
}

.health-data .type-class-elements .Checkbox {
    color   : var(--smartpcmh-green);
    padding : 0;
    border  : none;
    outline : none;
}

.health-data .type-class-elements .Checkbox.checked,
.health-data .type-class-elements .Checkbox:hover {
    color : var(--smartpcmh-green-dark);
}

.health-data .type-class-elements .Checkbox.MuiIconButton-colorSecondary:hover {
    background-color : transparent;
}

.attributes-delete-icon {
    color   : var(--smartpcmh-green);

    font-size     : 20pt;
    margin-top    : auto;
    margin-bottom : auto;
}

.type-class-text{
    font-size: 1.1vw;
    
}
